body {
  margin: 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

td, th, h1{
  text-align: center;
}

th{
  min-width: 80px;
}
.container {
  margin-left: 20px; /* Ajuste conforme necessário */
  /* ou use padding-left: 0; */
}

.Atualizar, .Deletar, .Imagem{
  cursor: pointer;
}

.input-field{
  margin: -5px;
  padding:-5px;
}

.table-container {
  max-height: 550px; /* Ajuste conforme necessário */
  overflow-y: auto;
  border: 1px solid #ccc; /* Opcional: para visualizar o contêiner */
  padding: 10px; /* Opcional: para um espaçamento extra */
}

button{
  margin-left: 10px;
  margin-top: 5px;
}

h4{
  text-align: center;
  margin-top: 0px;
  margin-bottom: 20px;
  font-weight: bold;
}
h5{
  font-weight: bold;
}
.BotaoDownload{
  float: left;
  margin-left: 5px;
  cursor: pointer;
}

.input-field input::placeholder {
  color: black; /* Cor do placeholder */
  opacity: 0.5; /* Opacidade do placeholder */
}

select::placeholder{
  color: black; /* Cor do placeholder */
  opacity: 0.5; /* Opacidade do placeholder */
}

#Seletor{
  border: 1px solid rgb(182, 171, 171); /* Borda transparente */
  background: rgba(255, 255, 255, 0.1); /* Cor de fundo semitransparente */
}

#Seletor:focus {
  border-color: #000000; /* Cor da borda ao focar */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Sombra ao focar */
}
.App{
  padding: 50px;
  padding-top: 10px;
}